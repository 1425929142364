import { Helmet } from "react-helmet";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import AboutLink from "./components/AboutLink";
import BannerSection from "./components/BannerSection";
import Collapsible from "./components/Collapsible";
import ExploreMore from "./components/ExploreMore";
import MaterialCard from "./components/MaterialCard";
import ScrollMenu from "./components/ScrollMenu";
import SideMenu from "./components/SideMenu";
import SEO from "../../components/seo";

import glossaryOfMaterialsPdf from "../../images/Ana Luisa - Glossary of Materials.pdf";

// Images
import firstBannerImgD from "../../images/about_images/Our Jewelry Page/Desktop/OurJewelry_hero_desktop.jpg";
import firstBannerImgM from "../../images/about_images/Our Jewelry Page/Mobile/OurJewelry_hero_mobile.jpg";
import secondBannerImgD from "../../images/about_images/Our Jewelry Page/Desktop/OurJewelry_tile2_desktop.jpg";
import secondBannerImgM from "../../images/about_images/Our Jewelry Page/Mobile/OurJewelry_tile2_mobile.jpg";
import thirdBannerImgD from "../../images/about_images/Our Jewelry Page/Desktop/OurJewelry_tile_packaging_desktop.jpg";
import thirdBannerImgM from "../../images/about_images/Our Jewelry Page/Mobile/OurJewelry_tile_packaging_mobile.jpg";
import goldPlatedBrassImg from "../../images/about_images/Our Jewelry Page/materials/GoldPlatedBrass.jpg";
import diamondImg from "../../images/about_images/Our Jewelry Page/materials/LabGrownDiamonds.jpg";
import silverImg from "../../images/about_images/Our Jewelry Page/materials/GoldPlatedSilver.jpg";
import rhodiumImg from "../../images/about_images/Our Jewelry Page/materials/RhodiumPlatedSterlingSilver.jpg";
import solidGoldImg from "../../images/about_images/Our Jewelry Page/materials/SolidGold.jpg";
import recycledGlassImg from "../../images/about_images/Our Jewelry Page/materials/RecycledGlass.jpg";

import * as Styles from "./ourJewelry/styles.module.css";

const menuItems = [
  {
    title: "Our Story",
    url: "/pages/our-journey",
    isActive: false,
  },
  {
    title: "Our Jewelry",
    url: "/pages/our-jewelry",
    isActive: true,
    subMenuItems: [
      {
        title: "Quality",
        url: "#quality",
      },
      {
        title: "Materials & Care",
        url: "#materials",
      },
      {
        title: "Packaging",
        url: "#packaging",
      },
    ],
  },
  {
    title: "Our People",
    url: "/pages/our-people",
    isActive: false,
  },
  {
    title: "Our Planet",
    url: "/pages/our-planet",
    isActive: false,
  },
];

function OurJewelry({ path }) {
  const designPhilosophyCopy = (
    <>
      <p className="al_p">
        Our design philosophy was thoughtfully crafted by our in-house team of designers and is
        constantly developing to meet the moment. We create intuitively, thoughtfully, responsibly,
        and inclusively. Our planet-friendly pieces are made for all sizes, gender expressions, and
        styles–always.
      </p>
      <br />
      <p className="al_p">
        Sustainability is at the foundation of our design process. We craft unique pieces from
        responsible, innovative, and recycled materials wherever possible. In 2022, 75.5% of our
        pieces were made of recycled base metals and 73.2% of our materials were RJC Code of
        Practices (COP) Certified, which indicates our vendors sourced metals responsibly.
      </p>
      <br />
      <p className="al_p">
        We’re committed to continuing to explore emerging sustainable methods and practices. It’s
        essential that our pieces earnestly serve all people and our planet.
      </p>
    </>
  );
  const traceabilityCopy = (
    <>
      <p className="al_p">
        Our Glossary of Materials and soon-to-be-launched Tier-2 Supplier Overview, provide in-depth
        detail about material compositions and life cycles leaving little to the imagination. We’ve
        got nothing to hide.
      </p>
      <br />
      <p className="al_p">
        Our materials are as qualified as they are inspired. From base metals to gemstones, our
        collections are sourced to respect the environment and accentuate your style without
        overextending your budget. We incorporate recycled materials whenever possible, balancing
        performance and longevity — because pieces that last are good for the environment.
      </p>
      <br />
      <p className="al_p">
        But we’re not stopping there. We’re exploring new ways to increase the percentage of
        recycled, reclaimed and upcycled materials in coming quarters. And we’ll be extending our
        lab-grown diamonds line and introducing new sustainable materials for more exciting designs
        to come!
      </p>
    </>
  );
  const firstHeaderMobile = (
    <h1 className={`al_h3 ${Styles.our_jewelry__content__first_section__title_mobile}`}>
      Jewelry Made to Last
    </h1>
  );
  const firstHeaderDesktop = <h1 className="al_h2">Jewelry Made to Last</h1>;
  const firstHeaderContent = useResponsiveWithHydrationFix(firstHeaderMobile, firstHeaderDesktop);

  const designPhilosophyCollapsible = (
    <Collapsible
      title="Our Design Philosophy"
      className={Styles.our_jewelry__content__first_section__collapsible}
    >
      {designPhilosophyCopy}
    </Collapsible>
  );
  const designPhilosophyExpanded = (
    <>
      <h3 className="al_h3">Our Design Philosophy</h3>
      {designPhilosophyCopy}
    </>
  );
  const designPhilosophyContent = useResponsiveWithHydrationFix(
    designPhilosophyCollapsible,
    designPhilosophyExpanded
  );

  const secondHeaderMobile = (
    <h3 className={`al_h3 ${Styles.our_jewelry__content__first_section__title_mobile}`}>
      Meet Our Materials
    </h3>
  );
  const secondHeaderDesktop = <h2 className="al_h2">Meet Our Materials</h2>;
  const secondHeaderContent = useResponsiveWithHydrationFix(
    secondHeaderMobile,
    secondHeaderDesktop
  );

  const thirdHeaderMobile = (
    <h3 className={`al_h3 ${Styles.our_jewelry__content__first_section__title_mobile}`}>
      Our Packaging
    </h3>
  );
  const thirdHeaderDesktop = <h2 className="al_h2">Our Packaging</h2>;
  const thirdHeaderContent = useResponsiveWithHydrationFix(thirdHeaderMobile, thirdHeaderDesktop);

  const traceabilityCollapsible = (
    <>
      <Collapsible title="Traceability">
        {traceabilityCopy}
        <br />
        <a href={glossaryOfMaterialsPdf} className="al_link" target="_blank" rel="noreferrer">
          Browse our glossary of materials
        </a>
      </Collapsible>
      <AboutLink
        to="/pages/our-people"
        className={Styles.our_jewelry__content__second_section__mobile_link}
      >
        Browse our manufacturing partners
      </AboutLink>
    </>
  );
  const traceabilityExpanded = (
    <>
      <h3 className="al_h3">Traceability</h3>
      {traceabilityCopy}
      <div className={Styles.our_jewelry__content__second_section__link_container}>
        <AboutLink to={glossaryOfMaterialsPdf} isPdf>
          Browse our glossary of materials
        </AboutLink>
        <AboutLink to="/pages/our-people">Browse our manufacturing partners</AboutLink>
      </div>
    </>
  );
  const traceabilityContent = useResponsiveWithHydrationFix(
    traceabilityCollapsible,
    traceabilityExpanded
  );

  return (
    <div className={Styles.our_jewelry}>
      <SEO
        title="Our Jewelry"
        description="Discover exquisite jewelry crafted with utmost quality and passion at Ana Luisa. As a sustainable company, we are committed to creating stunning pieces that not only enhance your style but also contribute to a greener future. Explore our collection and indulge in guilt-free luxury that reflects your values."
      />
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com${path}`} />
        <meta property="og:url" content={`https://www.analuisa.com${path}`} />
      </Helmet>
      <ScrollMenu active="jewelry" />
      <SideMenu menuItems={menuItems} />
      <div className={Styles.our_jewelry__content}>
        <BannerSection
          className={Styles.our_jewelry__content__first_section}
          imageSrc={{ desktop: firstBannerImgD, mobile: firstBannerImgM }}
          imageAlt="Ana Luisa Jewelry - Our Jewelry"
          landmark="quality"
        >
          {firstHeaderContent}
          <p className="al_p">Quality never goes out of style.</p>
          <br />
          <p className="al_p">
            Every Ana Luisa piece is rigorously tested to ensure our quality is unmatched. We use
            chemical and physical tests, and regular on-site inspections to guarantee our metals are
            safe, hypoallergenic, and tarnish-resistant. Our jewelry is made to stand the test of
            time, no matter what life throws at you. Backed by our 2-Year Warranty, our pieces are
            ensured to stay by your side in style.
          </p>
          <br />
          <p className="al_p">
            But don’t take our word for it. Our 50,000 five star reviews speak for themselves.
          </p>
          {designPhilosophyContent}
          <div className={Styles.our_jewelry__content__first_section__link_container}>
            <AboutLink to="/collections/bestsellers">Shop our best sellers</AboutLink>
            <AboutLink to="/collections/new">Shop our new arrivals</AboutLink>
          </div>
        </BannerSection>
        <BannerSection
          className={Styles.our_jewelry__content__second_section}
          imageSrc={{ desktop: secondBannerImgD, mobile: secondBannerImgM }}
          imageAlt="Ana Luisa Jewelry - Our Jewelry"
          landmark="materials"
        >
          {secondHeaderContent}
          <p className="al_p">
            Let us be clear...crystal clear...supply chains should never be opaque. We’re sharing
            more information about where our materials come from and how they are processed than
            ever before, setting a new standard of accountability for the jewelry industry.
          </p>
          <div className={Styles.our_jewelry__content__second_section__materials}>
            <MaterialCard
              title="Gold Plated Brass"
              desc="Brass is known for its strength and durability, and can last for a very long time with reasonable care and maintenance. We use brass and an extra-thick layer of recycled 14k gold for a sheen that captivates."
              info="Care: Gently rub your gold-plated jewelry with a soft jewelry cloth to restore its shine. Plated pieces can be prone to oxidation, so we recommend removing them before you swim, shower, or apply lotion, makeup, or perfume. To minimize scratches, store your piece in your Ana Luisa pouch or in a separate compartment in your jewelry box."
              img={{ desktop: goldPlatedBrassImg, mobile: goldPlatedBrassImg }}
              alt="Gold plated brass necklace"
            />
            <MaterialCard
              title="Rhodium Plated Sterling Silver"
              desc="A timeless staple of everyday collections, we can always count on the durability of our sterling silver. Our sterling silver styles are made of 99.9% certified recycled silver."
              info="Care: We strongly recommend removing your jewelry before engaging in any activities that can lead to contact with moisture or friction to maintain luster and prolong life. Gently rub your jewelry with a soft jewelry cloth to restore its shine. To minimize scratches, store your piece in your Ana Luisa pouch or in a separate compartment in your jewelry box."
              img={{ desktop: rhodiumImg, mobile: rhodiumImg }}
              alt="Gold plated brass necklace"
            />
            <MaterialCard
              title="Gold Plated Sterling Silver"
              desc="A timeless staple of everyday collections, our sterling silver is recycled and made of 92.5% pure silver. We then coat the piece with a thick layer of 14k gold for a sheen that captivates."
              info="Care: Avoid exposing your Sterling Silver piece to chlorinated water and to cleaning products that contain bleach. Use a soft cloth to clean your silver piece. To minimize scratches and nicks, store it in your Ana Luisa pouch or in a separate compartment in your jewelry box."
              img={{ desktop: silverImg, mobile: silverImg }}
              alt="Gold plated sterling silver Sia earrings"
            />
            <MaterialCard
              title="Solid Gold"
              desc="We released our collection of 100% recycled 10k solid gold in February 2023. This collection is Responsible Jewellery Council (RJC) COC Certified and produced in Mexico."
              info="Care: When you're not wearing your Solid Gold jewelry, store it carefully in your Ana Luisa pouch. If your Solid Gold jewelry begins to lose its initial high polish, use the polishing cloth from the Ana Luisa Jewelry Care Kit to bring back its shine."
              img={{ desktop: solidGoldImg, mobile: solidGoldImg }}
              alt="Lab grown diamond earrings"
            />
            <MaterialCard
              title="Lab Grown Diamonds"
              desc="Shine without the mine. Cruelty-free lab-grown diamonds have the same brilliance and clarity as natural diamonds and will keep their sparkle forever."
              info="Care: Clean your diamond piece with just warm water every day to keep its shine and sparkle, or soak it in a gentle degreasing solution (we recommend water with a few drops of mild dish soap). After soaking, use a soft, clean toothbrush to remove any remaining dirt."
              img={{ desktop: diamondImg, mobile: diamondImg }}
              alt="Lab grown diamond earrings"
            />
            <MaterialCard
              title="Recycled Glass"
              desc="In 2022, we introduced our recycled glass collection which includes 100% GRS certified recycled pre-consumer glass. As we continue to innovate our styles and materials, one goal stays the same: keeping materials out of landfill by creating intentionally responsible designs."
              info="Care: Handle recycled glass pieces with care, as components are fragile. Gently rub your jewelry with a soft jewelry cloth to restore its shine. After every use, clean your jewelry with a cotton ball or a very soft cloth to remove any dust and dirt. Plated pieces can be prone to oxidation, so we recommend removing before you swim, shower, or apply lotion, makeup, or perfume. To minimize scratches, store your piece in your Ana Luisa pouch or in a separate compartment in your jewelry box."
              img={{ desktop: recycledGlassImg, mobile: recycledGlassImg }}
              alt="Lab grown diamond earrings"
            />
          </div>
          {traceabilityContent}
        </BannerSection>
        <BannerSection
          className={Styles.our_jewelry__content__third_section}
          imageSrc={{ desktop: thirdBannerImgD, mobile: thirdBannerImgM }}
          imageAlt="Ana Luisa Jewelry - Our Jewelry"
          landmark="packaging"
        >
          {thirdHeaderContent}
          <p className="al_p">
            Packaging is a major part of the process. We want to ensure that our pieces make it to
            your door in environmentally-friendly materials. Our mailers, gift boxes, and jewelry
            carding are sourced from responsibly managed forests, vetted by an independent third
            party.
          </p>
        </BannerSection>
        <div className={Styles.our_jewelry__content__fourth_section}>
          <div className={Styles.our_jewelry__content__fourth_section__item}>
            <p
              className={`al_p al_p--bold ${Styles.our_jewelry__content__fourth_section__item__title}`}
            >
              Eco-Mailer
            </p>
            <p className="al_p">
              Our eco-mailers are made from 100% recycled cardboard and are certified from an
              independent third party, ensuring that mailer comes from responsibly managed forests.
            </p>
          </div>
          <div className={Styles.our_jewelry__content__fourth_section__item}>
            <p
              className={`al_p al_p--bold ${Styles.our_jewelry__content__fourth_section__item__title}`}
            >
              Product Cards
            </p>
            <p className="al_p">
              We work with an independent third-party to ensure our product cards come from forests
              that do good for the environment. They’re made from 30% recycled paper and 70% pulp,
              and produced from responsibly managed forests that provide environmental, social, and
              economic benefit.
            </p>
          </div>
          <div className={Styles.our_jewelry__content__fourth_section__item}>
            <p
              className={`al_p al_p--bold ${Styles.our_jewelry__content__fourth_section__item__title}`}
            >
              Matchboxes
            </p>
            <p className="al_p">
              Just like our product cards, our matchboxes are made from 30% recycled paper and 70%
              pulp, and produced from responsibly managed forests. Pro-tip: you can get creative and
              find new ways to reuse our matchboxes to give them a longer life.
            </p>
          </div>
          <div className={Styles.our_jewelry__content__fourth_section__item}>
            <p
              className={`al_p al_p--bold ${Styles.our_jewelry__content__fourth_section__item__title}`}
            >
              Gift Box
            </p>
            <p className="al_p">
              Give unboxing a better reputation, with our 100% recycled cardboard gift boxes.
            </p>
          </div>
          <div className={Styles.our_jewelry__content__fourth_section__item}>
            <p
              className={`al_p al_p--bold ${Styles.our_jewelry__content__fourth_section__item__title}`}
            >
              Earring/Necklace Card
            </p>
            <p className="al_p">Our earrings and necklaces are mounted on 100% recycled paper.</p>
          </div>
        </div>
        <ExploreMore current="jewelry" />
      </div>
    </div>
  );
}

export default OurJewelry;
