// extracted by mini-css-extract-plugin
export var our_jewelry = "styles-module--our_jewelry--6c7be";
export var our_jewelry__content = "styles-module--our_jewelry__content--c104d";
export var our_jewelry__content__first_section = "styles-module--our_jewelry__content__first_section--dc1a4";
export var our_jewelry__content__first_section__collapsible = "styles-module--our_jewelry__content__first_section__collapsible--7cdce";
export var our_jewelry__content__first_section__link_container = "styles-module--our_jewelry__content__first_section__link_container--0ee86";
export var our_jewelry__content__first_section__title_mobile = "styles-module--our_jewelry__content__first_section__title_mobile--911ab";
export var our_jewelry__content__fourth_section = "styles-module--our_jewelry__content__fourth_section--9bfd7";
export var our_jewelry__content__fourth_section__item = "styles-module--our_jewelry__content__fourth_section__item--8cd8a";
export var our_jewelry__content__fourth_section__item__title = "styles-module--our_jewelry__content__fourth_section__item__title--48123";
export var our_jewelry__content__second_section = "styles-module--our_jewelry__content__second_section--48e6b";
export var our_jewelry__content__second_section__link_container = "styles-module--our_jewelry__content__second_section__link_container--8b088";
export var our_jewelry__content__second_section__materials = "styles-module--our_jewelry__content__second_section__materials--53387";
export var our_jewelry__content__second_section__mobile_link = "styles-module--our_jewelry__content__second_section__mobile_link--290f9";
export var our_jewelry__content__third_section = "styles-module--our_jewelry__content__third_section--8f32c";